@media only screen and (max-width: 620px) {

  // Hide clutter elements on mobile
  .mobile-hide{ display: none !important; }

  // Don't split footer text
  //.footer-text{ float: left !important;}

  // Phone Friendly Margarine ;)
  html {
    margin: 0 1em !important;
    font-size: 14px;
  }
  .footer-text {
    font-size: 12px;
  }

}

@media only screen and (max-width: 475px) {
  html {
    font-size: 12px;
  }
  .footer-text {
    font-size: 10px;
  }

}
