/* Style inspiration and settings adapted from https://github.com/jgthms/web-design-in-4-minutes*/

/* Base */
html {
  font-size: 18px;
  margin: 0 1em;
}

/* 01 Font family */

@font-face {
  font-family: "Liberation Mono";
  src: local("Liberation Mono"), url("LiberationMono.woff2") format("woff2");
}

body {
  font-family: "Liberation Mono";
}
/* 03 Spacing */


.padded {
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 30px;
}

.main-content {
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 30px;
}

div.main-content h2 {
  padding-top: 15px;
  padding-bottom: 5px;
}

header h1, h5 {
  display: inline;
}

/* 04 Color and contrast */

body {
  color: #c5ccce;
  background-color: #2e1537;
}

h1,
h2,
strong {
  color: #dcdede;
}

header h5 {
  font-weight: lighter;
}

/* 05 Balance */

code {
  padding: 2px 4px;
  vertical-align: text-bottom;
}

/* 06 Primary color */

a {
  color: #A581B9;
}

/* 07 Secondary colors */

h1,
h2,
strong {
  font-weight: 500;
}

td {
  border-right: 10px;
  border-right-style: solid;
  border-right-color: transparent;
  vertical-align: top;
}
td:last-child {
  border-right: 0px;
}
