.post-info {
  padding-bottom: 30px;
  margin: 20px 0 5px;
  padding-top: 20px;
}

.post-content {
  line-height: 35px;
  padding-bottom: 50px;
}

.post-content h1, h3, code, pre {
  padding-bottom: 10px;
  padding-top: 10px;
}

.post-content h2 {
  padding-bottom: 20px;
  padding-top: 20px;
}