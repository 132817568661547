.header {
  padding-top: 35px
}

.header nav {
  padding-top: 10px;
  padding-bottom: 20px;
}

.header nav a {
  padding-right: 3px;
}