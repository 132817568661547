.category-summary {
  padding-top: 30px;
  padding-bottom: 30px;
}

.category-all-nav, .category-header {
  display: block;
  position: relative;
  line-height: 24px;
  margin: 5px 0;
  padding-bottom: 10px;
  padding-top: 10px;
}