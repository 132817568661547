@charset "utf-8";

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-center {
  text-align: center;
}